import * as React from "react";

// Import Swiper React components
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { isDesktop } from "react-device-detect";
import { StaticImage } from "gatsby-plugin-image";

const ModelSliderEV = () => {
  return (
    <div className="max-w-6xl mx-auto">
      <Swiper
        modules={[FreeMode]}
        freeMode={true}
        grabCursor={true}
        spaceBetween={25}
        centeredSlides={true}
        className={`relative ${
          isDesktop ? " !overflow-visible" : " overflow-hidden"
        }`}
      >
        <SwiperSlide>
          <div className="bg-white rounded w-72 md:w-[360px] ml-8 md:ml-0 overflow-hidden">
            <div className="flex items-center min-h-[110px] relative">
              <div className="relative overflow-hidden h-[185px] w-full">
                <StaticImage
                  alt="katsana"
                  src="../../../static/images/ev/ev-model/ioniq.jpg"
                  className="absolute object-none object-left-top w-[467px] h-[285px] md:-top-[80px] md:-left-[80px] -top-[35%] -left-[50%]"
                />
              </div>
            </div>
            <h5 className="text-xl font-semibold leading-none px-4 py-6 md:px-6 md:pt-2 md:pb-8">
              EV Cars / 300+ models
            </h5>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="bg-white rounded w-72 md:w-[360px] md:ml-0 overflow-hidden">
            <div className="flex items-center min-h-[110px] relative">
              <div className="relative overflow-hidden h-[185px] w-full">
                <StaticImage
                  alt="katsana"
                  src="../../../static/images/ev/ev-model/supersoco.jpg"
                  className="absolute object-none object-left-top w-[467px] h-[285px] md:-top-[80px] md:left-[40px] -top-[35%] -right-[0] -left-[9%]"
                />
              </div>
            </div>
            <h5 className="text-xl font-semibold leading-none px-4 py-6 md:px-6 md:pt-2 md:pb-8">
              EV Bikes / 10+ models
            </h5>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="bg-white rounded w-72 md:w-[360px] md:ml-0 overflow-hidden">
            <div className="flex items-center min-h-[110px] relative">
              <div className="relative overflow-hidden h-[185px] w-full">
                <StaticImage
                  alt="katsana"
                  src="../../../static/images/ev/ev-model/ec35.jpg"
                  className="absolute object-none object-left-top w-[467px] h-[285px] md:-top-[80px] md:-left-[160px] -top-[80px] -left-[70%]"
                />
              </div>
            </div>
            <h5 className="text-xl font-semibold leading-none px-4 py-6 md:px-6 md:pt-2 md:pb-8">
              EV Vans / 20+ models
            </h5>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="bg-white rounded w-72 md:w-[360px] md:ml-0 overflow-hidden">
            <div className="flex items-center min-h-[110px] relative">
              <div className="relative overflow-hidden h-[185px] w-full">
                <StaticImage
                  alt="katsana"
                  src="../../../static/images/ev/ev-model/trike.jpg"
                  className="absolute object-none object-left-top w-[467px] h-[285px] md:-top-[80px] md:left-[40px] -top-[35%] -right-[0] -left-[9%]"
                />
              </div>
            </div>
            <h5 className="text-xl font-semibold leading-none px-4 py-6 md:px-6 md:pt-2 md:pb-8">
              EV Trike / 5+ models
            </h5>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="bg-white rounded w-72 md:w-[360px] md:ml-0 overflow-hidden">
            <div className="flex items-center min-h-[110px] relative">
              <div className="relative overflow-hidden h-[185px] w-full">
                <StaticImage
                  alt="katsana"
                  src="../../../static/images/ev/ev-model/ev-truck.jpg"
                  className="absolute object-none object-left-top w-[467px] h-[285px] md:-top-[80px] md:-left-[10px] -top-[80px] -left-[10%]"
                />
              </div>
            </div>
            <h5 className="text-xl font-semibold leading-none px-4 py-6 md:px-6 md:pt-2 md:pb-8">
              EV Trucks / 10+ models
            </h5>
          </div>
        </SwiperSlide>

      </Swiper>
    </div>
  );
};

export default ModelSliderEV;
