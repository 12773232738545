import * as React from "react";
import LazyLoad from "react-lazyload";
import {
  MobileView,
  // MobileOnlyView,
  BrowserView,
  // TabletView,
} from "react-device-detect";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Cta from "../components/cta";
import { StaticImage } from "gatsby-plugin-image";
import EVSegments from "../components/ev/evsegments";
import EVPopularModels from "../components/ev/evpopularmodels";
import EVManagement from "../components/ev/evmanagement";
import EVFaqs from "../components/ev/evfaqs";
import ReachUs from "../components/ev/reachus";
import ReviewSliderEV from "../components/ev/reviewsliderev";
import * as Styles from "../styles/index.module.css";
import "../styles/signal.css";
import "../styles/herofeatures.css";

const EVPage = () => {
  // const breakpoints = useBreakpoint();
  return (
    <ParallaxProvider>
      <Layout pageTitle="Home Page">
        {/* MetaTag */}
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <title>
            EV Fleet Management and Operations Platform
          </title>
          <meta
            name="description"
            content="Manage Electric Vehicles on an integrated, unified fleet management platform. Combine charging & battery data from various EV makes & models; enabling an uninterrupted EV fleet operation."
          />
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <link rel="canonical" href="https://www.katsana.com/ev/" />

          {/* Open Graph */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.katsana.com/ev/" />
          <meta
            property="og:title"
            content="EV Fleet Management and Operations Platform"
          />
          <meta
            property="og:description"
            content="Manage Electric Vehicles on an integrated, unified fleet management platform. Combine charging & battery data from various EV makes & models; enabling an uninterrupted EV fleet operation."
          />
          <meta
            property="og:image"
            content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
          />
          <meta
            property="og:image:alt"
            content="EV Fleet Management System and Solutions"
          />
          <meta property="og:image:width" content="1280" />
          <meta property="og:image:height" content="640" />

          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@katsanagps" />
          <meta name="twitter:creator" content="@katsanagps" />

          <meta
            name="twitter:image"
            content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
          />
        </Helmet>

        {/* Hero Area */}
        <div
          className={
            Styles.tilt +
            " relative bg-gradient-to-br from-[hsla(211,55%,22%,1)] via-[hsla(211,45%,16%,1)] to-[hsla(211,45%,16%,1)] pt-20 md:pt-24 xl:pt-28 md:pb-0"
          }
        >
          
          {/* Set Max Width */}
          <div className="relative mx-auto max-w-primary">
            <div className="flex justify-center">
              
              {/* Hero Text & CTA */}
              <div className="flex flex-col px-8 pt-4 md:pt-12 lg:pt-4 pb-0 md:pb-8 lg:w-full xl:max-w-[880px] z-10 items-center justify-center">
                <p className="mb-2 leading-6 md:mb-2 md:leading-8 sm:text-md text-bluegray md:max-w-lg text-center">
                  Fleet Management Solution for Electric Vehicles
                </p>
                <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold text-white text-center leading-10 md:leading-16 lg:leading-none">
                  The EV Fleet Management & Operations Platform
                </h1>
                <p className="mt-4 text-sm leading-6 md:mt-7 md:leading-8 md:text-xl text-bluegray md:max-w-xl text-center">
                  Manage Electric Vehicles on an integrated, unified platform. 
                  Combine charging & battery data from various EV makes & models;
                  enabling an uninterrupted EV fleet operation.
                </p>

                <div className="mt-5 md:mt-8 flex flex-col md:flex-row md:items-center md:mt-0 md:text-center mb-5 md:mnb-0">
                  <a
                    href="https://apps.katsana.com/get-quotation"
                    className="mr-0 md:mr-4 mb-3 md:mb-0 self-start font-semibold text-white rounded-full md:self-center md:px-6 px-4 pt-2 pb-3 md:pb-3 md:pt-2 text-sm md:text-base leading-0 bg-primary"
                  >
                    Request Consultation
                  </a>
                  <a
                    href="tel:+60377334474"
                    className="font-semibold text-sm text-primary flex  flex-col md:block text-center md:text-left"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>or call us +603 7733 4474 </span>
                    <span>(Kuala Lumpur, GMT +8)</span>
                  </a>
                </div>

              </div>
            </div>

            <div className="relative -mb-8 md:-mb-16">
              <div className="max-w-6xl px-2 md:px-8 pt-0 pb-0 mx-auto flex justify-center">
                <div className="h-auto w-full text-center relative">
                  <div 
                    className={`${Styles.bubble1} absolute md:-right-50 md:top-10 z-10 hidden lg:block md:hidden`}
                    data-sal="slide-up"
                    data-sal-duration="500"
                    data-sal-easing="ease-in"
                  >

                    <div className="w-auto h-auto scale-75 md:scale-100 overflow-hidden">
                      <StaticImage
                        alt="katsana"
                        src="../../static/images/ev/hero-image/img-1.png"
                        className="h-auto rounded-md w-[381px] object-cover"
                      />
                    </div>
                  </div>

                  <div 
                    className={`${Styles.bubble2} absolute md:right-0 md:top-[210px] z-10 hidden lg:block md:hidden`}
                    data-sal="slide-up"
                    data-sal-duration="500"
                    data-sal-easing="ease-in"
                  >
                    <div className="w-auto h-auto scale-75 md:scale-100">
                      <StaticImage
                        alt="katsana"
                        src="../../static/images/ev/hero-image/img-2.png"
                        className="h-auto md:h-auto rounded-md w-[381px] object-cover"
                      />
                    </div>
                  </div>

                  <div 
                    className={`${Styles.bubble3} absolute md:left-[160px] md:bottom-[120px] z-10 hidden lg:block md:hidden`}
                    data-sal="slide-up"
                    data-sal-duration="500"
                    data-sal-easing="ease-in"
                  >
                    <div className="w-[381px] h-auto scale-75 md:scale-100">
                      <StaticImage
                          alt="katsana"
                          src="../../static/images/ev/hero-image/img-3.png"
                          className="h-auto md:h-auto rounded-md w-[381px] object-cover"
                        />
                    </div>
                  </div>

                  <div className="signal-wrapper absolute left-[55.4%] top-[48%] z-10">
                    <div className="signals">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    <div className="relative -top-[12px] right-[11px] z-10">
                      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="11.4373" cy="11.4373" r="10" transform="rotate(-8.97263 11.4373 11.4373)" fill="#0C427A"/>
                      <path d="M14.8026 7.21947L12.641 17.1519C12.6297 17.2036 12.603 17.2507 12.5644 17.2871C12.5259 17.3234 12.4773 17.3473 12.425 17.3556C12.4184 17.3567 12.4118 17.3577 12.4051 17.3582C12.3488 17.3626 12.2926 17.3488 12.2448 17.3189C12.1969 17.289 12.1599 17.2446 12.1391 17.1921L10.4221 12.8331L5.74614 12.5427C5.68979 12.5393 5.63606 12.5178 5.59286 12.4814C5.54967 12.4451 5.51931 12.3958 5.50627 12.3409C5.49323 12.2859 5.49819 12.2283 5.52043 12.1764C5.54266 12.1245 5.581 12.0812 5.62976 12.0527L14.4129 6.93605C14.457 6.9103 14.5077 6.89796 14.5587 6.90053C14.6097 6.9031 14.6589 6.92047 14.7002 6.95053C14.7416 6.98058 14.7732 7.02202 14.7914 7.06978C14.8096 7.11753 14.8135 7.16955 14.8026 7.21947Z" fill="white"/>
                      </svg>

                      <div class="tooltip relative w-[100px] -ml-[39px] -bottom-[8px]">
                        <div class="bottom text-xs">
                          <p>Hyundai Kona</p>
                          <p>77% / - 288km</p>
                          <i></i>
                        </div>
                      </div>

                    </div>

                  </div>

                  <MobileView>
                    <StaticImage
                      alt="katsana"
                      src="../../static/images/ev/hero-image/hero-mobile.png"
                      className="h-auto rounded-md w-full"
                    />
                  </MobileView>

                  <BrowserView>
                    <StaticImage
                      alt="katsana"
                      src="../../static/images/ev/hero-image/hero-map-marker.png"
                      className="h-auto rounded-md w-full md:w-[985px] lg:w-[985px]"
                    />
                  </BrowserView>

                </div>

              </div>
            </div>
          </div>

          <div className=" w-full z-10 rotate-[-1.7deg] sm:rotate-[-1.3deg] md:rotate-[-1.3deg] lg:rotate-[-1.3deg] 2xl:rotate-[-1.1deg] backdrop-opacity-10 backdrop-invert bg-bluedark/30 absolute bottom-[38px] sm:bottom-[80px] [clip-path:polygon(100%_100%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px]"></div>
        </div>

        {/* EV Charging Data */}
        <div className="bg-bluedark relative">
          <div className="z-20 bg-bluedark absolute -top-[24px] sm:-top-[39.5px] [clip-path:polygon(100%_100%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px]"></div>
          
          <div className="max-w-6xl mx-auto">
            <div>
              <div className="max-w-5xl py-1 md:py-0 lg:pt-3 lg:pb-5 mx-auto">
                <EVSegments />
              </div>
            </div>
          </div>

          <div className="bg-bluedark absolute -bottom-[22.5px] sm:-bottom-[38.5px] [clip-path:polygon(0%_0%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px] z-10"></div>
        </div>

        {/* EV Popular Models and Makes */}
        <div
          className={
            // "bg-gradient-to-b from-[hsla(202,28%,77%,1)] to-[hsla(198,26%,93%,1)] mt-[-1px] pb-0 relative"
            "bg-lightgray mt-[-1px] pb-0 relative"
          }
        > 
        
          {/* EV Popular Models */}
          <div className="w-full py-1 md:py-0 lg:pt-8 lg:pb-8 mx-auto">
            <EVPopularModels/>
          </div>
        
          
          <div className="skew-bottom bg-lightgray absolute -bottom-[22.5px] sm:-bottom-[38.5px] [clip-path:polygon(0%_0%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px] z-10"></div>
        </div>

        {/* EV Management */}
        <div
          className={
            "bg-offwhite mt-[-1px] pb-0 relative"
          }
        >
          <div className="rotate-[-183.5deg] md:rotate-[-181.5deg] backdrop-opacity-5 backdrop-invert bg-lightgray/30 absolute top-[11px] md:top-[19px] sm:top-[19px] [clip-path:polygon(100%_100%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px]"></div>
          
          <div className="max-w-6xl mx-auto">
            {/* EV Management */}
            <div className="max-w-5xl py-1 md:py-0 lg:pt-8 lg:pb-8 mx-auto">
              <EVManagement />
            </div>
          </div>

          <div className="skew-bottom bg-[hsla(198,26%,93%,1)] absolute -bottom-[22.5px] sm:-bottom-[38.5px] [clip-path:polygon(0%_0%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px] z-10"></div>
        </div>

        {/* Testimonials */}
        <div className="relative pt-16 md:pt-16 pb-0 md:pb-0 bg-[hsla(203,28%,82%,1)]">

          <ReachUs />

          <div className="flex flex-col max-w-5xl px-8 py-8 mx-auto md:items-center md:flex-row md:px-0">
            <div className="flex-1 ">
              <h2 className="text-2xl sm:text-4xl font-bold text-bluetext mb-6">
                Platform Reviews by EV Fleet Operators
              </h2>
              <p className="max-w-3xl mt-4 text-sm leading-6 md:text-base text-bluetext">
                Early EV adopters are relying on KATSANA EV Fleet Management System to help them navigate the transition to clean mobility. Together we grow to make the world greener for future generations.
                <br />
                <br />
                Reach out to us for your EV fleet management needs.
              </p>
            </div>

            <div className="md:ml-16">
              <div className="flex flex-col mt-8 md:items-center md:mt-0 md:text-center">
                <a
                  href="https://apps.katsana.com/get-quotation"
                  className="self-start px-5 py-2 font-semibold text-white rounded-full md:self-center md:px-12 md:py-3 md:text-base leading-0 bg-primary"
                >
                  Get Free Consultation
                </a>
                <a
                  href="tel:+60377334474"
                  className="mt-4 text-sm text-primary md:flex"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  or call us +603 7733 4474
                  <br />
                  (Kuala Lumpur, GMT +8)
                </a>
              </div>
            </div>
          </div>
          
          <LazyLoad>
            <ReviewSliderEV />
          </LazyLoad>

          <div className="mx-auto max-w-primary">
            <Parallax y={[-40, 5]} tagOuter="figure">
              <StaticImage
                alt="katsana"
                src="../../static/images/apad/apad-permit-renewal.webp"
                objectFit="cover"
                className="relative z-0 md:mt-[-100px] mt-[-100px]  h-[200px] md:h-auto"
              />
            </Parallax>
          </div>
        </div>

        {/* EV FAQ */}
        <div
          className={
            " bg-gradient-to-b from-[hsla(202,28%,77%,1)] to-[hsla(198,26%,93%,1)] mt-[-1px] md:pt-6 pb-24 md:pb-36 relative"
          }
        >
          <div className="max-w-6xl mx-auto px-8 md:px-4">
            {/* EV */}
            <div className="max-w-3xl py-8 md:py-0 lg:pt-8 lg:pb-8 mx-auto">
              <EVFaqs />
            </div>
          </div>

          <div className="skew-bottom bg-[hsla(198,26%,93%,1)] absolute -bottom-[22.5px] sm:-bottom-[38.5px] [clip-path:polygon(0%_0%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px] z-10"></div>
        </div>

        <Cta />

      </Layout>
    </ParallaxProvider>
  );
};

export default EVPage;
