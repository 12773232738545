import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";

function EVSegments() {

  return (
    <div className="px-8 pt-8 md:pt-6 pb-10 md:p-8 lg:p-8">

      <div className="max-w-3xl mx-auto items-center justify-center mb-12 md:mb-16">
        <p className="pb-4 text-base text-center md:text-base text-bluegray">
          Trusted by largest EV & mixed fleet operators in South East Asia
        </p>

        <div className="flex flex-col sm:flex-row justify-center items-center mt-2">
          <div className="flex justify-center items-center mb-4 md:mb-0">
            <div className="mx-3">
              <StaticImage
                alt="Customer Logo"
                src="../../../static/images/ev/customer-logo/pos-malaysia.png"
                className="h-full w-[50px] md:w-full md:h-[42px] object-fit"
              />
            </div>

            <div className="mx-3">
              <StaticImage
                alt="Customer Logo"
                src="../../../static/images/ev/customer-logo/gentari.png"
                className="h-full w-[70px] md:w-full md:h-[44px] object-fit"
              />
            </div>

            <div className="mx-3">
              <StaticImage
                alt="Customer Logo"
                src="../../../static/images/ev/customer-logo/avis.png"
                className="h-full w-[60px] md:w-full md:h-[30px] object-fit mt-1 md:mt-0"
              />
            </div>
          </div>

          <div className="flex justify-center items-center">
            <div className="mx-3">
              <StaticImage
                alt="Customer Logo"
                src="../../../static/images/ev/customer-logo/eco-ride.png"
                className="h-full w-[80px] md:w-full md:h-[40px] object-fit"
              />
            </div>

            <div className="mx-3">
              <StaticImage
                alt="Customer Logo"
                src="../../../static/images/ev/customer-logo/drb-hicom.png"
                className="h-full w-[100px] md:w-full md:h-[33px] object-fit"
              />
            </div>
          </div>

        </div>
      </div>

      <h2 className="max-w-xl mx-0 sm:mx-6 mt-0 md:mt-2 text-2xl font-bold text-white md:text-4xl md:leading-[2.75rem]">
        <span className="text-primary">Real-time EV battery & charging data</span> across all vehicles models and body types
      </h2>

      <p className="max-w-xl mx-0 sm:mx-6 mt-4 mb-7 md:mb-14 lg:mb-14 text-xs leading-5 md:text-lg text-bluegray md:leading-8">
        KATSANA makes it easy for fleet operators to retrieve real-time 
        charging and state-of-charge data of popular EV makes & models, all on a unified EV operations plattform
      </p>

      <div className="flex flex-col sm:flex-row items-stretch justify-center">
        <div className="mt-4 sm:mt-0 w-full pb-8">
          <div className="sm:w-full overflow-hidden relative pb-10">
            <LazyLoad>
              <StaticImage
                alt="EV Models"
                src="../../../static/images/ev/ev-segment/ev-models.png"
                className="w-full object-fit h-full"
              />              
              
              <div className="absolute z-10 w-[107px] h-[105px] sm:w-[225px] sm:h-[267px] bottom-1 md:bottom-0 left-10 md:left-[180px]">
                <StaticImage
                  alt="EV Models"
                  src="../../../static/images/ev/ev-segment/e-bike.png"
                  className="w-full object-fit h-full"
                />
              </div>
            </LazyLoad>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-start justify-start w-full"> 
        
        <div className="w-full md:w-1/3 px-3 flex flex-col items-center justify-center relative mb-4 md:mb-0">
          <div className="w-[60px] h-[60px] md:w-[75px] md:h-[75px] rounded-lg bg-white shadow-2xl -mb-3 relative z-20">
            <StaticImage
              alt="Icon"
              src="../../../static/images/ev/ev-segment/ic-1.png"
              className="w-full object-fit h-full"
            />
          </div>
          <div className="relative">
            <h3 className="text-white text-xl absolute top-7 z-10 font-bold px-10 text-center">
              Real-time Vehicle
              State-of-Charge Data
            </h3>
            <StaticImage
              alt="EV Segment"
              src="../../../static/images/ev/ev-segment/cardbg-SoC.jpg"
              className="w-full object-fit h-[300px] max-h-[300px] rounded-lg"
            />
          </div>
          <div className="w-full px-4 -mt-[120px] relative z-20">
            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">EV Battery charge</span>
                </div>
                <span className="ml-auto text-sm text-gray-500">48%</span>
              </div>
            </div>

            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">EV Battery health report</span>
                </div>

              </div>
            </div>

            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">Fleet summary</span>
                  <span className="text-bluetext text-xs font-medium">Charging Required</span>
                </div>
                <span className="ml-auto text-sm text-gray-500">4/37 vehicles</span>
              </div>
            </div>

            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">Remaining distance</span>
                </div>
                <span className="ml-auto text-sm text-gray-500">338km</span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/3 px-3 flex flex-col items-center justify-center relative mb-4 md:mb-0">
          <div className="w-[60px] h-[60px] md:w-[75px] md:h-[75px] rounded-lg bg-white shadow-2xl -mb-3 relative z-20">
            <StaticImage
              alt="Icon"
              src="../../../static/images/ev/ev-segment/ic-2.png"
              className="w-full object-fit h-full"
            />
          </div>
          <div className="relative">
            <h3 className="text-white text-xl absolute top-7 z-10 font-bold px-10 text-center">
              Real-time Vehicle
              Charging Data
            </h3>
            <StaticImage
              alt="EV Segment"
              src="../../../static/images/ev/ev-segment/cardbg-charging.jpg"
              className="w-full object-fit h-[300px] max-h-[300px] rounded-lg"
            />
          </div>
          <div className="w-full px-4 -mt-[120px] relative z-20">
            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">Charging speed</span>
                </div>
                <span className="ml-auto text-sm text-gray-500">198kW</span>
              </div>
            </div>

            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">Charging type</span>
                </div>
                <span className="ml-auto text-sm text-gray-500">DC</span>
              </div>
            </div>

            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">Estimated completion time</span>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">Fleet Summary</span>
                  <span className="text-bluetext text-xs font-medium">Currently charging</span>
                </div>
                <span className="ml-auto text-sm text-gray-500">12/37 vehicles</span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/3 px-3 flex flex-col items-center justify-center relative">
          <div className="w-[60px] h-[60px] md:w-[75px] md:h-[75px] rounded-lg bg-white shadow-2xl -mb-3 relative z-20">
            <StaticImage
              alt="Icon"
              src="../../../static/images/ev/ev-segment/ic-3.png"
              className="w-full object-fit h-full"
            />
          </div>
          <div className="relative">
            <h3 className="text-white text-xl absolute top-7 z-10 font-bold px-10 text-center">
              Real-time Vehicle
              Telematics Data
            </h3>
            <StaticImage
              alt="EV Segment"
              src="../../../static/images/ev/ev-segment/cardbg-telematics.jpg"
              className="w-full object-fit h-[300px] max-h-[300px] rounded-lg"
            />
          </div>
          <div className="w-full px-4 -mt-[120px] relative z-20">
            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">Speed & Odometer</span>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">Location</span>
                  <span className="text-bluetext text-xs font-medium">Clarke Quay, Singapore</span>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">Driver</span>
                  <span className="text-bluetext text-xs font-medium">Phua Chu Kang</span>
                </div>
                <span className="ml-auto text-sm text-gray-500">BLE</span>
              </div>
            </div>

            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">Doors</span>
                </div>
                <span className="ml-auto text-sm text-gray-500">Locked</span>
              </div>
            </div>

            <div className="bg-white rounded-lg my-3 shadow-md">
              <div className="flex items-center justify-start w-full px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-bluetext font-bold">Seatbelt</span>
                </div>
                <span className="ml-auto text-sm text-gray-500">Yes</span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    
  );
};

export default EVSegments;
