import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";
import ModelSliderEV from "./modelsliderev";

function EVPopularModels() {

  return (
    <div className="pt-12 pb-8 md:p-8 lg:pt-8 lg:pb-2">

      <h2 className="md:px-12 px-8 max-w-5xl mx-auto mt-0 md:mt-8 text-2xl font-bold text-white md:text-4xl md:leading-[2.75rem]">
        Retrieve data from popular EV models in Asia, Europe and North America.
      </h2>

      <div className="max-w-6xl pt-8 pb-3 md:py-12 mx-auto">
        <LazyLoad>
          <ModelSliderEV/>
        </LazyLoad>
      </div>

      <div className="px-8 max-w-5xl mx-auto flex flex-col sm:flex-row items-start justify-center w-full z-10 mt-2 sm:mt-0">        
        
        <div className="w-full sm:w-1/2 px-0 sm:px-6 mb-3 sm:mb-0">
          <div className="text-bluetext text-sm sm:text-base">
            <p className="my-3">
              <span className="font-semibold">Retrieving real-time data from Electric Vehicles is our specialisation.</span>
            </p>
            <p className="my-3">
              KATSANA provides EV fleet operators and owners with the most coverage of popular Electric Vehicles in the market. 
              We commit to integrate electric vehicles from manufacturers in Asian, Northen American and European regions.
            </p>
            <p className="my-3">
              Reach out us to know more on the list of supported EVs.
            </p>
          </div>
        </div>

        <div className="w-full sm:w-1/2 px-0 sm:px-6">
          <div className="">
            <LazyLoad>
              <StaticImage
                alt="EV Models"
                src="../../../static/images/ev/ev-model/ev-manufacturers-logo.png"
                className="w-full object-fit h-full"
              />              
            </LazyLoad>
          </div>
        </div>
        
      </div>

    </div>

    
  );
};

export default EVPopularModels;
