import * as React from "react";
// import LazyLoad from "react-lazyload";
import { StaticImage } from "gatsby-plugin-image";

function ReachUs() {

  return (
    <div className="px-8 md:pt-8 md:pb-16">

      <h2 className="max-w-3xl mx-auto mt-0 md:mt-0 mb-2 text-2xl font-bold text-blueone  md:text-center md:text-4xl md:leading-[2.75rem]">
        Reach out to us.
      </h2>

      <div className="max-w-5xl px-0 py-4 md:py-8 mt-0 md:mt-6 md:pb-4 mx-auto flex flex-col items-center justify-center">

        <div className="grid grid-cols-1 gap-4 md:grid-cols-4 md:gap-6 w-full z-10">

          <a href="https://apps.katsana.com/get-quotation/" className="bg-[#184488] rounded-xl overflow-hidden">
            <div className="flex flex-col items-center relative">
              <div className="pt-6 px-6 md:px-6 md:pt-6 lg:px-6 lg:pt-6">
                <span className="text-white text-xs mb-2 block uppercase font-semibold">
                  EV Dealers
                </span>
                <h3 className="mb-4 font-bold text-base lg:text-base text-white leading-4 md:leading-6 lg:leading-normal">
                  Enterprise EV sales >
                </h3>
                <p className="text-white text-sm md:mb-4 mb-0">
                  Increase sales by hastening customers transition to electric vehicles
                </p>
              </div>

              <div className="relative overflow-hidden h-[180px] w-full">
                <StaticImage
                  alt="katsana"
                  src="../../../static/images/ev/reach-us/ev-dealers.png"
                  className="absolute object-none object-left-top w-[217px] h-[266px] left-[35%]"
                />
              </div>
            </div>
          </a>

          <a href="https://apps.katsana.com/get-quotation/" className="bg-[#1752BC] rounded-xl overflow-hidden">
            <div className="flex flex-col items-center relative">
              <div className="pt-6 px-6 md:px-6 md:pt-6 lg:px-6 lg:pt-6">
                <span className="text-white text-xs mb-2 block uppercase font-semibold">
                  EV Leasing Operators
                </span>
                <h3 className="mb-4 font-bold text-base lg:text-base text-white leading-4 md:leading-6 lg:leading-normal">
                  Lease management >
                </h3>
                <p className="text-white text-sm md:mb-4 mb-0">
                  Provide customers with comprehensive EV fleet management tools
                </p>
              </div>

              <div className="relative overflow-hidden h-[180px] w-full">
                <StaticImage
                  alt="katsana"
                  src="../../../static/images/ev/reach-us/ev-leasing.png"
                  className="absolute object-none object-left-top w-[217px] h-[266px] left-[35%]"
                />
              </div>
            </div>
          </a>

          <a href="https://apps.katsana.com/get-quotation/" className="bg-[#1752BC] rounded-xl overflow-hidden">
            <div className="flex flex-col items-center relative">
              <div className="pt-6 px-6 md:px-6 md:pt-6 lg:px-6 lg:pt-6">
                <span className="text-white text-xs mb-2 block uppercase font-semibold">
                  EV Fleet Operators
                </span>
                <h3 className="mb-4 font-bold text-base lg:text-base text-white leading-4 md:leading-6 lg:leading-normal">
                  Charging scheduling >
                </h3>
                <p className="text-white text-sm md:mb-4 mb-0">
                  Real-time EV state-of-charge and charging status reports to ensure timely departures
                </p>
              </div>

              <div className="relative overflow-hidden h-[180px] w-full">
                <StaticImage
                  alt="katsana"
                  src="../../../static/images/ev/reach-us/ev-operators.png"
                  className="absolute object-none object-left-top w-[217px] h-[266px] left-[35%]"
                />
              </div>
            </div>
          </a>

          <a href="https://apps.katsana.com/get-quotation/" className="bg-[#D1E2EF] rounded-xl overflow-hidden">
            <div className="flex flex-col items-center relative">
              <div className="pt-6 px-6 md:px-6 md:pt-6 lg:px-6 lg:pt-6">
                <span className="text-bluetext text-xs mb-2 block uppercase font-semibold">
                  Solution Providers
                </span>
                <h3 className="mb-4 font-bold text-base lg:text-base text-bluetext leading-4 md:leading-6 lg:leading-normal">
                  Be a Partner >
                </h3>
                <p className="text-bluetext text-sm md:mb-4 mb-0">
                  Retrieve customers EV data and integrate into your own fleet solutions
                </p>
              </div>

              <div className="relative overflow-hidden h-[180px] w-full">
                <StaticImage
                  alt="katsana"
                  src="../../../static/images/ev/reach-us/system-integrators.png"
                  className="absolute object-none object-left-top w-[217px] h-[266px] md:left-[20%] left-[35%]"
                />
              </div>
            </div>
          </a>

        </div>

      </div>

    </div>
    
  );
};

export default ReachUs;
