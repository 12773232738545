import * as React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import "../../styles/accordion.css";

const EVFaqs = () => {
  return (
    <div className="block rounded-2xl sm:px-6 w-full">
      <h2 className="text-blueone text-2xl sm:text-4xl md:text-center font-bold mb-4 md:mb-4">Frequently Asked Questions about EV Fleet Management System</h2>
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">1. What is Fleet Management for electric vehicles (EVs)?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Fleet management for electric vehicles (EVs) involves overseeing and optimizing the operation, maintenance, and logistics of a group of electric vehicles within an organization. Similar to that of traditional fleet management, it however has specific considerations related to EV technology. 
                <br/>
                <br/>
                In particular, KATSANA EV fleet management system involves real-time data gathering from the EVs such as State-of-Charge, Charging status, energy consumption and carbon impact assessment. Another important component of EV fleet management is Range & Route planning to ensure that vehicles can complete their tasks without running out of power.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">2. How can Fleet Management software optimize the efficiency of an EV fleet?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                An EV Fleet Managemnet solution provides several integral monitoring capability such as battery health and State-of-Charge monitoring, smart charging scheduling, energy consumption tracking and charging infrastructure optimizations. Features in KATSANA EV Fleet Management system ensures that vehicles are charged appropriately, on time and reduces the risk of downtime due to low battery levels.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">3. What are the key features of Fleet Management for electric vehicles?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Fleet management for electric vehicles (EVs) includes several key features tailored to the unique needs of EV fleets. These features help optimize the performance, efficiency, and sustainability of the fleet.
                <br/>
                <br/>
                These features are among others, real-time vehicle status monitoring (battery and charging status), route optimisations, charging management, sustainability and environmental impact and compliance & regulatory reports.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">4. Can Fleet Management software provide real-time updates on EV charging status?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Yes, KATSANA fleet management software can provide real-time updates on the charging status of electric vehicles (EVs). This capability is crucial for managing an EV fleet efficiently, ensuring that vehicles are charged and ready for use when needed. 
                <br/>
                <br/>
                When telematics hardware is installed onto the EVs, KATSANA will provide data on charging status, charging session summary, and notifications on important charging events. These real-time updates on EV charging status provided by KATSANA fleet management software enhance the overall efficiency of fleet operations, reduce downtime, and help in making informed decisions to keep the fleet running smoothly. 
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">5. How does the software manage and schedule charging for multiple EVs?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Managing and scheduling charging for multiple electric vehicles (EVs) in a fleet is a complex task that KATSANA EV fleet management software can streamline effectively. It involves several capabilities such as smart charging scheduling, charging time optimizations (off-peak charging hours & time-based charging), dynamic vehicle availability adjustment & load balancing, vehicle & task coordination and charging completion alerts.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">6. How does Fleet Management software contribute to reducing the carbon footprint of an EV fleet?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                KATSANA EV Fleet management software contributes significantly to reducing the carbon footprint of an electric vehicle (EV) fleet by optimizing various aspects of fleet operations, energy use, and sustainability practices.
                <br/>
                <br/>
                Firstly, the software helps to optimize energy consumption by providing efficient route planning & embedding eco-driving techniques that are more energy-efficient. With smart charging management, the software can recommend off-peak charging sessions when the demand on the power grid is lower and electricity generation is often cleaner. This reduces the fleet’s reliance on fossil fuel-based electricity, thus lowering its carbon footprint.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

            <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">7. Can the software provide reports on the environmental impact and sustainability metrics?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                 Yes, KATSANA fleet management software can provide detailed reports on the environmental impact and sustainability metrics of an electric vehicle (EV) fleet. These reports are essential for organizations that aim to track their progress toward sustainability goals, comply with environmental regulations, and demonstrate their commitment to reducing their carbon footprint. Some of the reports includes Carbon Emissions Tracking, Energy Consumption Reporting, Driver Behavior Scorecard (DriveMark) and Sustainability goals tracking.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

            <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">8. Is the Fleet Management software compatible with all EV models?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                KATSANA EV Fleet Management solution is compatible with most popular EVs in the market. This includes major American, European and Asian EVs. 
                <br/>
                <br/>
                We commit to integrating newly released EVs as requested by our customers.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>


            <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">9. How does the software integrate with existing systems and hardware in the fleet?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
               The fleet management solution is designed to be hardware-agnostic, enabling it to receive data from a myriad of datasources, such as existing telematic hardware, OEM's telematics cloud or through other systems via API. 
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>


    <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">10. Can the software be customized to meet specific fleet needs?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Yes definitely. Customisation is a key value proposition in KATSANA which allows fleet operators to tailor the fleet management platform to fit your unique operational requirements. This ensures that the fleet solution can effectively support your fleet management goals.
              <br/>
              <br/>
              Possible customisations may include dedicated dashboard and interfaces, custom reports and analytics, integration with existing systems in the fleet operation and tailored workflow automation.
              <br/>
              <br/>
              Some of our customers may also require adaptation to specific industry needs, for example in Agriculture, Plantations, Ports and Energy industries.</p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

          <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">11. What types of reports and analytics does Fleet Management software provide for EV fleets?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                KATSANA EV Fleet Management solution provides a wide range of reports & analytics to help fleet operators monitor performance, optimize operations and make informed decisions with regard to their EV operations. These reports and analytics cover various aspects of fleet management, from vehicle utilization to environmental impact.
                <br/>
                <bv/>
                Some key reports include Vehicle Utilization Reports, Energy Consumption Reports, Charging Reports, Battery Health Reports, Carbon Emission Report, Driver Behavior Report, Total Cost of Ownership Report, Fleet Performance Report, and custom reports as required by the customers.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

          <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">12. Can the software provide insights on driver behavior and efficiency?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Yes, this capability play a big part of KATSANA EV Fleet Management solution. Combined with Driver ID upgrade, fleet operators are able to quantify the behavior of drivers on the road, identifying risky drivers, and improve overall fleet safety.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>


          <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">13. How can Fleet Management software assist in transitioning from a traditional to an EV fleet?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Transitioning from a traditional fleet of internal combustion engine (ICE) vehicles to an electric vehicle (EV) fleet is a complex process that requires careful planning, analysis, and management. Fleet management software can play a crucial role in facilitating this transition by providing the tools and insights needed to make informed decisions and ensure a smooth conversion. 
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>



          <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">14. Are there training and onboarding services for new users?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Yes, customer training and onboarding is served by a dedicated department in KATSANA. These sessions are designed to ensure that fleet managers can effectively utilise our platform and maximize its benefits for managing your fleet. 
                <br/>
                <br/>
                You may request for training session at anytime through our customer success channel.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>      

    </div>
  );
};

export default EVFaqs;
