import * as React from "react";
// import LazyLoad from "react-lazyload";
import { StaticImage } from "gatsby-plugin-image";

function EVManagement() {

  return (
    <div className="px-8 pt-12 pb-8 md:p-8 lg:p-8">

      <h2 className="max-w-3xl mx-auto mt-0 md:mt-12 mb-2 sm:mb-4 text-2xl font-bold text-blueone  md:text-center md:text-4xl md:leading-[2.75rem]">
        Integrated fleet operation solutions for all your EV management needs
      </h2>

      <div className="max-w-5xl px-0 py-8 mt-0 md:mt-10 sm:p-4 pb-4 mx-auto flex flex-col items-center justify-center">

        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6 lg:gap-7 w-full z-10">

          <div className="group bg-gradient-to-b from-[#1F3E75] via-[#234582] to-[#284E91] flex flex-col items-start rounded-xl relative overflow-hidden duration-700 relative">
            <div className="pt-4 pb-6 px-4 md:px-6 md:pt-6 md:pb-6 lg:px-6 lg:pt-6 lg:pb-8">
              <span className="text-white text-xs mb-2 block">
                Vehicle Health
              </span>
              <h3 className="mb-4 font-bold text-sm md:text-base lg:text-lg text-white leading-4 md:leading-6 lg:leading-normal">
                State-of-Charge (SoC) Dashboard
              </h3>
              <p className="text-white text-sm mb-4">
                Overview of the vehicles SoC data across the entire fleet in real-time including detailed energy use and efficiency reports.
              </p>
              <p className="text-white text-sm">
                Ensures timely trip departures with optimum charge level.
              </p>
            </div>
          </div>

          <div className="group bg-gradient-to-b from-[#1F3E75] via-[#234582] to-[#284E91] flex flex-col items-start rounded-xl relative overflow-hidden duration-700 relative">
            <div className="pt-4 pb-6 px-4 md:px-6 md:pt-6 md:pb-6 lg:px-6 lg:pt-6 lg:pb-8">
              <span className="text-white text-xs mb-2 block">
                EV Charging Status
              </span>
              <h3 className="mb-4 font-bold text-sm md:text-base lg:text-lg text-white leading-4 md:leading-6 lg:leading-normal">
                Charging Ops Dashboard
              </h3>
              <p className="text-white text-sm mb-4">
                Overview of charging operation across the entire fleet in real-time including charging type (AC/DC) and detailed charging history reports.
              </p>
              <p className="text-white text-sm">
                Prevents overcharging events to prolong battery life.
              </p>
            </div>
          </div>

          <div className="group bg-gradient-to-b from-[#1F3E75] via-[#234582] to-[#284E91] flex flex-col items-start rounded-xl relative overflow-hidden duration-700 relative">
            <div className="pt-4 pb-6 px-4 md:px-6 md:pt-6 md:pb-6 lg:px-6 lg:pt-6 lg:pb-8">
              <span className="text-white text-xs mb-2 block">
                EV Battery Health
              </span>
              <h3 className="mb-4 font-bold text-sm md:text-base lg:text-lg text-white leading-4 md:leading-6 lg:leading-normal">
                State-of-Health (SoH) & Degradation Report
              </h3>
              <p className="text-white text-sm mb-4">
                Track battery health & capacity of your EVs, including the battery degradation over time.
              </p>
            </div>
          </div>

          <div className="group bg-gradient-to-b from-[#1F3E75] via-[#234582] to-[#284E91] flex flex-col items-start rounded-xl relative overflow-hidden duration-700 relative">
            <div className="pt-4 pb-6 px-4 md:px-6 md:pt-6 md:pb-6 lg:px-6 lg:pt-6 lg:pb-8">
              <span className="text-white text-xs mb-2 block">
                EV Maintenance Records
              </span>
              <h3 className="mb-4 font-bold text-sm md:text-base lg:text-lg text-white leading-4 md:leading-6 lg:leading-normal">
                Service & Maintenance Dashboard
              </h3>
              <p className="text-white text-sm mb-4">
                Real-time insights and control over the maintenance needs and service schedules of electric vehicles (EVs) within the fleet.
              </p>
              <p className="text-white text-sm">
                Logs all past maintenance and service activities for each vehicle, providing a complete history that can be reviewed for future reference or compliance purposes.
              </p>
            </div>
          </div>

          <div className="group bg-gradient-to-b from-[#1F3E75] via-[#234582] to-[#284E91] flex flex-col items-start rounded-xl relative overflow-hidden duration-700 relative">
            <div className="pt-4 pb-6 px-4 md:px-6 md:pt-6 md:pb-6 lg:px-6 lg:pt-6 lg:pb-8">
              <span className="text-white text-xs mb-2 block">
                Driver Compliance Record
              </span>
              <h3 className="mb-4 font-bold text-sm md:text-base lg:text-lg text-white leading-4 md:leading-6 lg:leading-normal">
                Driver Management & Digital Driver Logbook
              </h3>
              <p className="text-white text-sm mb-4">
                Record & track driver activities, hours of service (HOS), and other relevant information related to the operation of fleet vehicles.
              </p>
              <p className="text-white text-sm">
                A crucial tool for modern fleet management, offering enhanced accuracy, compliance, and operational efficiency over traditional paper-based logbooks.
              </p>
            </div>
          </div>

          <div className="group bg-gradient-to-b from-[#1F3E75] via-[#234582] to-[#284E91] flex flex-col items-start rounded-xl relative overflow-hidden duration-700 relative">
            <div className="pt-4 pb-6 px-4 md:px-6 md:pt-6 md:pb-6 lg:px-6 lg:pt-6 lg:pb-8">
              <span className="text-white text-xs mb-2 block">
                Driver Behavior & Safety
              </span>
              <h3 className="mb-4 font-bold text-sm md:text-base lg:text-lg text-white leading-4 md:leading-6 lg:leading-normal">
                Real-time MDVR with AI, ADAS & DSM
              </h3>
              <p className="text-white text-sm mb-4">
                Stream live video feeds from cameras on the vehicles (up to 4 channels). This allows for real-time observation of the vehicle's surroundings, driver behavior, and road conditions.
              </p>
                <p className="text-white text-sm">
                Provides concrete evidence in accidents; invaluable for insurance claims, legal proceedings, and determining fault.
              </p>
            </div>
          </div>

        </div>

        <div className="mt-4 sm:mt-7 w-full max-h-full md:max-h-80 mx-3 group flex flex-col sm:flex-row items-center rounded-xl shadow-2xl overflow-hidden duration-700 relative bg-white">
         
          <div className="w-full sm:w-5/12 p-6 sm:p-8">
            <div className="mb-4 md:mb-8">
              <StaticImage
                alt="KATSANA logo"
                src="../../../static/images/logos/katsana.png"
                className="w-7/12 object-fit"
              />
            </div>
            <h3 className="mb-4 font-semibold text-sm md:text-lg lg:text-xl text-bluetext leading-4 md:leading-6 lg:leading-6">
              Well-integrated with other KATSANA solutions making the fleet efficient & cost-effective
            </h3>
            <p className="text-sm sm:text-md text-bluetext">
              <b>KATSANA EV fleet management system</b> works seamlessly within the KATSANA ecosystem, providing fleet operators with a robust suite of fleet management solutions.
            </p>
          </div>

          <div className="w-full sm:w-7/12 pt-0 md:pt-4 px-4 md:pr-4">
            <StaticImage
              alt="fleet management system"
              src="../../../static/images/fuel/kplatform.jpg"
              className="w-full object-cover h-full relative -mb-4 md:-mb-12"
            />
          </div>
        </div>

      </div>

    </div>
    
  );
};

export default EVManagement;
